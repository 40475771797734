export const themeColors = [
    '#6c78eb',
    '#9CECE3',
    '#543CEF',
    '#17a2b8',
    '#007bff',
];

export const contextColors = {
    primary: '#6c78eb',
    secondary: '#6c757d',
    brand_1: '#6c78eb',
    'brand-1': '#6c78eb',
    brand_2: '#543CEF',
    'brand-2': '#543CEF',
    brand_3: '#9CECE3',
    'brand-3': '#9CECE3',
    success: '#30c596',
    warning: '#d9b71f',
    danger: '#e5466f',
};
